//#region Private functions
const enrichDefaults = function (baseConfig, settings) {
  // Disable zoom and fullscreen controls
  baseConfig.default.showControls = false;
  // Disable compass because we use a minimap
  baseConfig.default.compass = false;
  // Assign themed firstScene
  baseConfig.default.firstScene = `${settings.defaultTimeOfDay}_T1_${baseConfig.default.firstScene}`;
  // Auto load our first scene
  baseConfig.default.autoLoad = true;
  // Enable gyro
  baseConfig.default.orientationOnByDefault = true;
  return baseConfig.default;
};
// Scene hotspot creation function
function createSceneHotspot(hotSpotDiv, args) {
  // Tooltip
  hotSpotDiv.classList.add("eye-hotspot-tooltip");
  var span = document.createElement("span");
  span.innerHTML = args.text;
  hotSpotDiv.appendChild(span);
  span.style.width = span.scrollWidth + 1 + "px";
  span.style.marginLeft =
    -(span.scrollWidth - hotSpotDiv.offsetWidth - 7) / 2 + "px";
  span.style.marginTop = span.scrollHeight * 1.4 + "px";
  span.style.scale = args.scale;
  // Expanding bubble
  var bubble = document.createElement("div");
  bubble.classList.add("eye-hotspot");
  bubble.style.backgroundImage = `url(${args.thumbnailUrl})`;
  bubble.style.scale = args.scale;
  hotSpotDiv.appendChild(bubble);
  // Arrow
  var arrow = document.createElement("div");
  arrow.classList.add("eye-hotspot-arrow");
  arrow.style.transformOrigin = top;
  arrow.style.scale = args.scale;
  hotSpotDiv.appendChild(arrow);
}
// Label hotspot creation function
function createLabelHotspot(hotSpotDiv, args) {
  // Tooltip
  hotSpotDiv.classList.add("info-hotspot-tooltip");
  var span = document.createElement("span");
  span.innerHTML = args.text;
  hotSpotDiv.appendChild(span);
  span.style.width = span.scrollWidth + 1 + "px";
  span.style.marginLeft =
    -(span.scrollWidth - hotSpotDiv.offsetWidth - 7) / 2 + "px";
  span.style.marginTop = span.scrollHeight * 0.8 + "px";
  span.style.scale = args.scale;
  // Expanding bubble
  var bubble = document.createElement("div");
  bubble.classList.add("info-hotspot");
  bubble.style.scale = args.scale;
  hotSpotDiv.appendChild(bubble);
  // Arrow
  var arrow = document.createElement("div");
  arrow.classList.add("info-hotspot-arrow");
  arrow.style.scale = args.scale;
  hotSpotDiv.appendChild(arrow);
}
//#region Public functions
export const applyDefaultStyles = function () {
  let mainContainer = document.querySelector(".pnlm-container");
  // Update the background image of our main container
  if (mainContainer) mainContainer.classList.add("splash-screen");
  // Disable scene info as we will handle this ourselves
  let info = document.querySelector(".pnlm-panorama-info");
  // Note: Using display will not work when in tour mode and switch scene
  if (info) info.style.visibility = "hidden";
  // Disable load box
  let loadBox = document.querySelector(".pnlm-load-box");
  if (loadBox) {
    loadBox.style.backgroundColor = "rgba(210, 210, 210, 0.5)";
    loadBox.style.backdropFilter = "blur(10px)";
    loadBox.style.borderRadius = "25px";
    loadBox.style.color = "#2d9bf0";
    //loadBox.style.visibility = "hidden";
  }
};
export const injectClasses = function (baseConfig, uiScale, hotspotCallback) {
  var tempConfig = baseConfig;
  for (var sceneKey in tempConfig.scenes) {
    tempConfig.scenes[sceneKey] = getSceneWithClasses(
      tempConfig.scenes[sceneKey],
      tempConfig.scenes,
      uiScale,
      hotspotCallback
    );
  }
  return tempConfig;
};
export const getSceneWithClasses = function (
  sceneData,
  sceneMap,
  uiScale,
  hotspotCallback
) {
  var tempScene = sceneData;
  var isMap = sceneMap instanceof Map;
  for (var hotspotKey in tempScene.hotSpots) {
    var hotspot = tempScene.hotSpots[hotspotKey];
    switch (hotspot.type) {
      case "scene":
        hotspot.cssClass = "eye-hotspot-base";
        hotspot.createTooltipFunc = createSceneHotspot;
        // Create a reference to our scene
        var sceneRef = isMap
          ? sceneMap.get(hotspot.sceneId)
          : sceneMap[hotspot.sceneId];
        // Grab the hotspot thumbnail
        var thumbnail = sceneRef.thumbnail;
        // Add our text scale, and thumbnail url as an argument
        hotspot.createTooltipArgs = {
          text: hotspot.text,
          scale: uiScale,
          thumbnailUrl: thumbnail,
        };
        hotspot.clickHandlerArgs = hotspot.sceneId;
        hotspot.clickHandlerFunc = hotspotCallback;
        break;
      case "label":
        hotspot.cssClass = "info-hotspot-base";
        hotspot.createTooltipFunc = createLabelHotspot;
        // Add our text as an argument
        hotspot.createTooltipArgs = { text: hotspot.text, scale: uiScale };
        break;
    }
  }
  return tempScene;
};
export const getWalkthroughMap = function (configObject) {
  var configArray = Array.from(configObject.panoWalkthroughs);
  var configMap = new Map();
  configArray.forEach((walkthrough) => {
    walkthrough.default = enrichDefaults(walkthrough, configObject.settings);
    configMap.set(walkthrough.sceneType, walkthrough);
  });
  return configMap;
};
export const getAllScenes = function (configMap) {
  var allScenes = new Map();
  configMap.forEach((configObject, configKey) => {
    var themes = Object.entries(configObject.themes);
    themes.forEach(({ 0: themeKey }) => {
      // Create a deep copy of our scenes to not modify the original
      var scenesCopy = Object.entries(
        JSON.parse(JSON.stringify(configObject.scenes))
      );
      scenesCopy.forEach(({ 0: sceneKey, 1: sceneValue }) => {
        // Grab scene's hotspots
        var hotspots = sceneValue.hotSpots;
        // Assign hotspots with theme
        hotspots.forEach((hotspot) => {
          if (hotspot.type === "scene")
            hotspot.sceneId = `${configKey}_T${themeKey}_${hotspot.sceneId}`;
        });
        sceneValue.hotSpots = hotspots;
        // Assign thumbnail
        sceneValue.thumbnail =
          configObject.rootAssetFilepath + sceneValue.thumbnail;
        // Assign themed panorama
        sceneValue.panorama =
          configObject.rootAssetFilepath +
          Object.entries(sceneValue.themes).at(themeKey - 1)[1].panorama;
        const newKey = `${configKey}_T${themeKey}_${sceneKey}`;
        allScenes.set(newKey, sceneValue);
      });
    });
  });
  return Object.fromEntries(allScenes.entries());
};
export const getDefaultConfig = function (configMap, settings) {
  return configMap.get(settings.defaultTimeOfDay);
};
export const getSceneMap = function (configObject) {
  // Create entries of the config's scenes
  var sceneEntries = Object.entries(configObject.scenes);
  // Return map from entries
  return new Map(sceneEntries);
};

export const getLevelMap = function (configMap, timeOfDay) {
  var levelEntries = [];
  if (configMap) {
    var config = configMap.get(timeOfDay);
    if (config) {
      var tempLevels = JSON.parse(JSON.stringify(config.minimap.levels));
      // Grab our levels as entries
      levelEntries = Object.entries(tempLevels);
      levelEntries.forEach(({ 1: level }) => {
        level.image = config.rootAssetFilepath + level.image;
      });
    }
  }
  // Create map from level's keys and values
  return new Map(levelEntries);
};
export const getThemeMap = function (configMap, timeOfDay) {
  var themeEntries = [];
  if (configMap) {
    var config = configMap.get(timeOfDay);
    if (config) {
      var tempThemes = JSON.parse(JSON.stringify(config.themes));
      // Grab themes as entries
      themeEntries = Object.entries(tempThemes);
      themeEntries.forEach(({ 1: theme }) => {
        theme.themeThumbnail = config.rootAssetFilepath + theme.themeThumbnail;
      });
    }
  }
  // Create map from themes
  return new Map(themeEntries);
};
export const getCarrouselData = function (configObject) {
  const configScenes = Object.entries(configObject.scenes);
  let carrouselData = [];
  configScenes.forEach(({ 1: s }) => {
    carrouselData.push({
      title: s.title,
      thumbnail: configObject.rootAssetFilepath + s.thumbnail,
    });
  });
  return carrouselData;
};
export const getRawSceneId = function (sceneId) {
  return sceneId.split("_")[2];
};
