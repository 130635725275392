<template>
  <div
    class="hotSpotDot"
    @mouseenter="onMouseOver"
    @mouseleave="onMouseLeave"
  ></div>
</template>

<script setup>
import { computed, defineProps, onMounted } from "vue";
import * as StyleUtil from "@/util/StyleUtil";

const props = defineProps({
  title: { type: String, required: true },
  position: {
    x: Number,
    y: Number,
    required: true,
  },
});

const emit = defineEmits(["hoverDot", "leaveDot"]);
const xPosition = computed(() => `${props.position.x}%`);
const yPosition = computed(() => `${props.position.y}%`);

const onMouseOver = function (e) {
  //console.log(e);
  emit("hoverDot", props.title);
};
const onMouseLeave = function (e) {
  //console.log(e);
  emit("leaveDot");
};

const hueShift = computed(() => {
  var hexColor = document.documentElement.style.getPropertyValue(
    "--ls-highlight-color2"
  );
  return `${StyleUtil.getHueFromHex(hexColor)}deg`;
});

const brightness = computed(() => {
  var hexColor = document.documentElement.style.getPropertyValue(
    "--ls-highlight-color2"
  );
  var tempRgb = StyleUtil.hex2rgb(hexColor);
  return StyleUtil.getBrightnessFromRgb(tempRgb.r, tempRgb.g, tempRgb.b);
});
</script>

<style scoped>
.hotSpotDot {
  cursor: pointer;
  position: absolute;
  left: calc(v-bind(xPosition) - 15px);
  bottom: calc(v-bind(yPosition) - 15px);
  height: 30px;
  width: 30px;
  background-image: url("../../assets/Minimap_Hotspot_Red.png");
  filter: hue-rotate(v-bind(hueShift)) brightness(v-bind(brightness));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  z-index: 11;
  pointer-events: auto;
  transform-origin: center;
  transition: transform 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.hotSpotDot:hover {
  /* filter: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"><defs><filter id="689d94" color-interpolation-filters="sRGB"><feColorMatrix type="matrix" values="0 0 0 0 0.40784 0 0 0 0 0.61569 0 0 0 0 0.58039 0 0 0 1 0"/></filter></defs></svg>#689d94'); */
  transform: scale(1.2);
}
</style>
