<template>
  <div class="card-container">
    <img
      :src="props.cardThumbnail"
      class="card-img"
      :class="{ cardImgSelected: isSelected }"
    />
    <span class="card-title" :class="{ cardTitleSelected: isSelected }">{{
      props.cardTitle
    }}</span>
    <div class="card-outline" :class="{ cardOutlineSelected: isSelected }">
      <div class="card-arrow" :class="{ cardArrowSelected: isSelected }" />
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  selectedTitle: { type: String, required: true },
  cardTitle: { type: String, required: true },
  cardThumbnail: { type: String, required: true },
});

const isSelected = computed(() => props.selectedTitle == props.cardTitle);
</script>
<style scoped>
.card-container {
  @apply relative inline-block h-full w-auto flex-none cursor-pointer;
  transform-origin: bottom;
  transition: transform 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.card-img {
  @apply relative h-full cursor-pointer box-border;
  filter: brightness(0.5);
  transform-origin: bottom;
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.card-title {
  @apply absolute left-0 top-0 flex items-center justify-center
  w-full h-full py-1 text-white text-center font-medium uppercase
  px-8 lg:px-10 text-xl lg:text-xs;
  transform-origin: center;
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.card-container:first-child {
  @apply rounded-l-xl;
  transform-origin: bottom left;
}
.card-container:first-child > .card-outline,
.card-container:first-child > .card-img {
  @apply rounded-l-xl;
}
.card-container:last-child {
  @apply rounded-r-xl;
  transform-origin: bottom right;
}
.card-container:last-child > .card-outline,
.card-container:last-child > .card-img {
  @apply rounded-r-xl;
}
.card-container:hover {
  @apply z-10;
  transform: scale(1.125, 1);
}
.card-container:hover > .card-img {
  filter: brightness(1);
  transform: scale(1, 1.125);
}
.card-container:hover > .card-title {
  @apply text-white;
  transform: scale(1, 1.125);
  text-shadow: 3px 0px 7px rgb(28, 28, 28), -3px 0px 7px rgb(28, 28, 28),
    0px 4px 7px rgb(28, 28, 28), 3px 0px 7px rgb(28, 28, 28),
    -3px 0px 7px rgb(28, 28, 28), 0px 4px 7px rgb(28, 28, 28);
}

.card-container:hover > .card-outline > .card-arrow::before {
  content: "▲";
}
.cardArrowSelected,
.card-container:hover > .card-outline > .card-arrow {
  @apply absolute -bottom-2 w-auto h-auto text-ls-highlight-color1
  text-xl lg:text-base;
  -webkit-transform: scale(2, 1); /* Safari and Chrome */
  -moz-transform: scale(2, 1); /* Firefox */
  -ms-transform: scale(2, 1); /* IE 9 */
  -o-transform: scale(2, 1); /* Opera */
  transform: scale(2, 1); /* W3C */
  left: calc(50% - 12px);
}

.cardOutlineSelected,
.card-container:hover > .card-outline {
  @apply visible absolute left-0 top-0 w-full h-full border-ls-highlight-color1 
  border-4 lg:border-[3px];
}
/* Selection styles */
.cardArrowSelected::before {
  content: "▲";
}
.cardImgSelected {
  filter: brightness(1);
}
.cardTitleSelected {
  @apply text-white;
  text-shadow: 3px 0px 7px rgb(28, 28, 28), -3px 0px 7px rgb(28, 28, 28),
    0px 4px 7px rgb(28, 28, 28), 3px 0px 7px rgb(28, 28, 28),
    -3px 0px 7px rgb(28, 28, 28), 0px 4px 7px rgb(28, 28, 28);
}
</style>
