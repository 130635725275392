<template>
  <div class="main-container">
    <div class="map-container" v-if="props.isVisible">
      <div class="title-container">
        <span class="to-room-text">{{ toRoomText }}</span>
      </div>
      <div class="map-area">
        <img class="mini-map" :src="levelBackground" ref="miniMapImg" />
        <div class="mini-map-dot-container">
          <div class="position">
            <div class="orientation">
              <div class="offset"></div>
            </div>
          </div>
          <mini-map-dot
            v-for="[spotKey, spotValue, index] in hotspotMap"
            :key="index"
            :title="spotKey"
            :position="getDotPosition(spotValue)"
            @click="onHotspotSelected(spotKey)"
            @hover-dot="onDotEnter"
            @leave-dot="onDotLeave"
          />
        </div>
        <button class="close-button" @click="onClose()" />
      </div>
      <div class="level-area">
        <button
          class="rounded-bl-xl border-r-2"
          :class="[displayDown ? 'activeLevel' : 'inactiveLevel']"
          @click="levelDown()"
        >
          <div class="left-arrow" />
        </button>
        <span class="level-text">{{ props.currentLevel }}</span>
        <button
          class="rounded-br-xl border-l-2"
          :class="[displayUp ? 'activeLevel' : 'inactiveLevel']"
          @click="levelUp()"
        >
          <div class="right-arrow" />
        </button>
      </div>
    </div>
    <div class="open-button-container" v-else>
      <button class="open-button" @click="onOpen()">
        <img src="../../assets/Map_Icon.png" /><span
          class="open-button-separator"
          >|</span
        ><span class="font-semibold">Map</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  defineProps,
  defineEmits,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
} from "vue";
import MiniMapDot from "./MiniMapDot.vue";
import * as StyleUtil from "@/util/StyleUtil";

const props = defineProps({
  isVisible: { type: Boolean, required: true },
  levels: { type: Map, required: true },
  rotation: {
    type: Number,
    required: true,
  },
  liveScene: {
    type: String,
    required: true,
  },
  currentLevel: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  "onClose",
  "selectHotspot",
  "update:isVisible",
  "changeLevel",
]);
// Reactive Properties
const minimapWidth = ref(0);
const toRoomText = ref("");
const levelKeyIndex = ref(0);
// Dynamic refs
const miniMapImg = ref(null);

//#region Watchers
// Watch for level changes when a scene loads
watch(props, (newProps) => {
  var levelKeys = [...props.levels.keys()];
  var keyIndex = levelKeys.indexOf(newProps.currentLevel);
  if (levelKeyIndex.value != keyIndex) {
    levelKeyIndex.value = keyIndex;
  }
});
// Set the minimap width based on the size of the minimap image
watch(miniMapImg, (newImg) => {
  if (newImg) {
    newImg.onload = function () {
      //console.log(this.width);
      minimapWidth.value = this.width;
    };
  }
});
//#region Mounted
onMounted(() => {
  window.addEventListener("resize", onWindowResize);
});
onUnmounted(() => {
  window.removeEventListener("resize", onWindowResize);
});
//#region Functions
const getDotPosition = function (hotspotValue) {
  return { x: hotspotValue.xLocation, y: hotspotValue.yLocation };
};
const onHotspotSelected = function (hotspotKey) {
  console.log(`Selected hotspot: ${hotspotKey}`);
  emit("selectHotspot", hotspotKey);
};
const onClose = function () {
  emit("update:isVisible", false);
  emit("onClose");
};
const onOpen = function () {
  emit("update:isVisible", true);
};
const levelUp = function () {
  if (levelKeyIndex.value < props.levels.size - 1) {
    levelKeyIndex.value++;
    changeLevel();
  }
};
const levelDown = function () {
  if (levelKeyIndex.value > 0) {
    levelKeyIndex.value--;
    changeLevel();
  }
};
const changeLevel = function () {
  var levelKeys = [...props.levels.keys()];
  emit("changeLevel", levelKeys[levelKeyIndex.value]);
};
const onDotEnter = function (title) {
  toRoomText.value = title;
};
const onDotLeave = function () {
  toRoomText.value = "";
};
const onWindowResize = function (eventData) {
  if (miniMapImg.value) minimapWidth.value = miniMapImg.value.width;
};
//#region Computed
const hueShift = computed(() => {
  var hexColor = document.documentElement.style.getPropertyValue(
    "--ls-highlight-color2"
  );
  return `${StyleUtil.getHueFromHex(hexColor)}deg`;
});

const brightness = computed(() => {
  var hexColor = document.documentElement.style.getPropertyValue(
    "--ls-highlight-color2"
  );
  var tempRgb = StyleUtil.hex2rgb(hexColor);
  return StyleUtil.getBrightnessFromRgb(tempRgb.r, tempRgb.g, tempRgb.b);
});

const displayToRoomText = computed(() => {
  return toRoomText.value == "" ? "none" : "block";
});
const hotspotMap = computed(() => {
  var hotspotEntries = levelData.value
    ? Object.entries(levelData.value.hotSpots)
    : null;
  return new Map(hotspotEntries);
});
const levelData = computed(() => {
  return props.levels.get(props.currentLevel ? props.currentLevel : "L1");
});

const displayDown = computed(() => levelKeyIndex.value > 0);
const displayUp = computed(() => levelKeyIndex.value < props.levels.size - 1);

// This is our minimap image based on our current level
const levelBackground = computed(() => {
  return levelData.value ? levelData.value.image : "";
});

const getRotation = computed(() => `${props.rotation.toFixed(2) - 180}deg`);

const currentHotspot = computed(() => {
  return hotspotMap.value ? hotspotMap.value.get(props.liveScene) : null;
});

const getCameraXPos = computed(() => {
  return currentHotspot.value ? `${currentHotspot.value.xLocation}%` : "50%";
});
const getCameraYPos = computed(() => {
  return currentHotspot.value ? `${currentHotspot.value.yLocation}%` : "50%";
});
const mapWidthStyle = computed(() => {
  return `${minimapWidth.value}px`;
});
//#endregion
</script>

<style scoped>
.position {
  position: absolute;
  left: calc(v-bind(getCameraXPos) - 30px);
  bottom: calc(v-bind(getCameraYPos) - 30px);
  /* background-color: #2d9bf0; */
  height: 60px;
  width: 60px;
  z-index: 12;
  pointer-events: none;
}
.orientation {
  height: 100%;
  width: 100%;
  rotate: v-bind(getRotation);
  pointer-events: none;
}
.offset {
  position: relative;
  bottom: 16px;
  background-image: url("../../assets/Minimap_PlayerIcon_Red.png");
  filter: hue-rotate(v-bind(hueShift)) brightness(v-bind(brightness));
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.mini-map {
  @apply relative h-full select-none mx-auto;
}
.main-container {
  @apply absolute lg:w-[30vh] lg:h-[30vh] lg:right-[2em] lg:bottom-[1em] lg:p-0 z-10 pointer-events-none
  w-full h-2/3 right-0 bottom-0 p-4;
}
.map-container {
  @apply relative w-full h-full pointer-events-auto;
}
.map-area {
  @apply relative rounded-xl
  lg:w-full lg:h-full lg:px-10 lg:pb-10 lg:pt-8
  w-full h-full py-10;
  /* pb-[66.666667%]; */

  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}
.mini-map-dot-container {
  position: absolute;
  left: 2.5rem;
  top: 2rem;
  width: v-bind(mapWidthStyle);
  height: calc(100% - 4.5rem);
  margin-left: calc(((100% - 5rem) - v-bind(mapWidthStyle)) * 0.5);
  margin-right: calc(((100% - 5rem) - v-bind(mapWidthStyle)) * 0.5);
  pointer-events: none;
}
.title-container {
  @apply absolute top-0 w-full z-10;
}
.to-room-text::before {
  content: "TO ";
  color: var(--ls-primary-font-color);
}
.to-room-text {
  @apply w-full py-2 text-center text-base text-ls-secondary-font-color font-semibold uppercase;
  display: v-bind(displayToRoomText);
  text-shadow: 3px 0px 7px rgba(255, 255, 255, 0.5),
    -3px 0px 7px rgba(255, 255, 255, 0.5), 0px 4px 7px rgba(255, 255, 255, 0.5);
}
.level-area {
  @apply absolute bottom-0 left-0 w-full rounded-b-xl flex flex-row gap-2
  h-10 lg:h-[12%];
  background-color: rgba(255, 255, 255, 0.8);
}
.level-area button {
  @apply p-2;
  border-color: rgba(125, 125, 125, 0.35);
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.level-area button:hover {
  @apply bg-white;
}
.level-text {
  @apply grow text-lg font-semibold my-auto text-center text-ls-primary-font-color select-none;
}
.level-area button:hover > .left-arrow {
  filter: brightness(0.8);
}
.left-arrow {
  @apply h-full rounded-tr-xl float-left
  w-8 lg:w-10;
  background-image: url("../../assets/Minimap_Arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  rotate: 180deg;
  filter: brightness(1.2);
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.level-area button:hover > .right-arrow {
  filter: brightness(0.8);
}
.right-arrow {
  @apply h-full rounded-br-xl float-right
  w-8 lg:w-10;
  background-image: url("../../assets/Minimap_Arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  filter: brightness(1.2);
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.close-button {
  @apply absolute lg:right-[-5%] lg:top-[-6%] lg:w-[18%] lg:h-[18%]
  -right-4 -top-4 w-16 h-16;
  background-image: url("../../assets/close-circle-svgrepo-com.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 12;
  pointer-events: auto;
}
.open-button {
  @apply text-sm uppercase rounded-xl px-2 flex flex-row items-center gap-1.5
  pointer-events-auto;
  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.28);
  position: absolute;
  right: 0%;
  bottom: 0%;
  z-index: 12;
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.open-button:hover {
  @apply bg-white;
}
.open-button img {
  @apply h-10 py-2;
}
.open-button-separator {
  @apply text-lg font-normal text-gray-300 select-none;
}
.open-button-container {
  @apply w-full h-full pointer-events-none
  hidden lg:block;
}
.hideArrow {
  @apply hidden bg-none;
}
.activeLevel {
  @apply bg-gray-400;
}
.inactiveLevel {
  @apply bg-gray-200;
}
</style>
