<template>
  <div class="main-container">
    <div class="toggle-area">
      <label
        class="flex items-center relative w-max cursor-pointer select-none"
      >
        <input
          type="checkbox"
          v-model="toggleState"
          class="appearance-none transition-colors cursor-pointer w-[5.5rem] h-8 rounded-full focus:outline-none ring-2 ring-offset-1 ring-offset-white ring-white bg-white"
        />
        <span
          class="w-[1.45rem] h-[1.45rem] right-[3.7rem] absolute rounded-full transform transition-transform bg-[#efefefc9] ring-2 ring-white ring-offset-1"
        />
      </label>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from "vue";

const props = defineProps({ checked: { type: Boolean, required: true } });
const emit = defineEmits(["update:checked", "onChange"]);

const toggleState = computed({
  get() {
    return props.checked;
  },
  set(newState) {
    emit("update:checked", newState);
    emit("onChange", newState);
  },
});
</script>

<style scoped>
.main-container {
  @apply absolute z-10
  right-4 top-4 lg:right-8 lg:top-8;
  transition: all 0.15s;
  transform-origin: top right;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
  border-radius: 9999px;
}
.main-container:hover {
  @apply lg:scale-125 lg:shadow-xl;
}
.toggle-area {
  @apply md:w-full mb-6 md:mb-0 grid;
}
input {
  background-image: url("../../assets/TOD_Slider_Sunset.png");
  background-size: contain;
}
input:checked {
  background-image: url("../../assets/TOD_Slider_Day.png");
}

input:checked ~ span:last-child {
  --tw-translate-x: 3.39rem; /* translate-x-7 */
}
</style>
