<template>
  <fullscreen v-model="fullscreen">
    <pannellum-viewer @on-toggle-fullscreen="toggle" />
  </fullscreen>
</template>

<script>
import PannellumViewer from "./components/PannellumViewer.vue";
import fullscreen from "vue-fullscreen";

export default {
  name: "App",
  components: {
    PannellumViewer,
  },
  methods: {
    toggle() {
      this.fullscreen = !this.fullscreen;
    },
  },
  data() {
    return {
      fullscreen: false,
    };
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
</style>
