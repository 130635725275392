<template>
  <div class="theme-body">
    <img
      :src="props.themeData.themeThumbnail"
      class="theme-img"
      :class="{ selectedImg: isSelectedTheme }"
    />
    <div class="theme-info" :class="{ selectedInfo: isSelectedTheme }">
      <span
        class="theme-description"
        :class="{ selectedDescription: isSelectedTheme }"
        >{{ cardDescription }}</span
      >
      <span class="theme-price" :class="{ selectedPrice: isSelectedTheme }">{{
        cardPrice
      }}</span>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { formatThousands } from "@/util/RegexUtil";

const props = defineProps({
  selectedTheme: { type: String, required: true },
  themeId: { type: String, required: true },
  themeData: { type: Object, required: true },
});

const cardDescription = computed(() => `${props.themeData.themeName} | `);

const cardPrice = computed(
  () => `$${formatThousands(props.themeData.themePrice)}`
);

const isSelectedTheme = computed(() => {
  return props.selectedTheme == props.themeId;
});
</script>

<style scoped>
.theme-body {
  @apply relative cursor-pointer rounded-lg
  py-1 pr-0 lg:py-0 lg:pr-2
  w-44 lg:w-full h-full lg:h-auto;
}
.theme-img {
  @apply relative w-full cursor-pointer rounded-t-lg border-t-[3px] border-x-[3px] border-white;
  height: 82%;
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.theme-info {
  @apply relative inline-block w-full p-2 rounded-b-lg text-center font-medium
  select-none cursor-pointer bg-gray-50 border-b-[3px] border-x-[3px] border-white
  text-2xs lg:text-sm;
  height: 18%;
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.302);
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.theme-description {
  @apply font-semibold text-ls-secondary-font-color;
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.selectedDescription {
  @apply text-ls-tertiary-font-color;
}
.theme-price {
  @apply text-ls-tertiary-font-color;
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.selectedPrice {
  @apply text-white;
}
.theme-body:hover > .theme-info > .theme-description {
  @apply text-ls-tertiary-font-color;
}
.theme-body:hover > .theme-info > .theme-price {
  @apply text-white;
}
.theme-body:hover > .theme-info {
  @apply bg-ls-list-view-bg-color border-b-[3px] border-x-[3px] border-ls-primary-font-color;
}
.theme-body:hover > .theme-img {
  @apply border-t-[3px] border-x-[3px] border-ls-primary-font-color;
}
.selectedInfo {
  @apply bg-gray-50 border-b-[3px] border-x-[3px] border-ls-primary-font-color bg-ls-list-view-bg-color;
}
.selectedImg {
  @apply border-t-[3px] border-x-[3px] border-ls-primary-font-color;
}
</style>
