<template>
  <div id="pannellumContainer" ref="containerRef"></div>
  <div v-if="displayUI">
    <div
      v-if="walkthroughSettings.displayRoomName"
      class="room-title-container"
      :class="{ invisibleOnMobile: hideRoom }"
    >
      <div class="room-title-box">
        <span class="room-title">{{ liveSceneRaw }}</span>
      </div>
    </div>
    <mini-map
      v-if="walkthroughSettings.enableMinimap"
      :levels="levels"
      :rotation="yaw"
      :live-scene="liveSceneRaw"
      :current-level="currentLevel"
      v-model:is-visible="toggleMinimap"
      @select-hotspot="warpToHotspot"
      @change-level="onLevelChange"
      @on-close="showMobileUI"
    />
    <info-panel-widget
      v-if="walkthroughSettings.displayInfoPanel && !isEmbedded"
      :walkthrough-settings="walkthroughDetails"
      :theme-price="getThemePrice"
      :display-attributes="walkthroughSettings.displayAttributes"
      :display-description="walkthroughSettings.displayDescription"
      :display-footage="walkthroughSettings.displayFootage"
      :display-price="walkthroughSettings.displayPrice"
      @on-toggle-panel="onToggleInfoPanel"
    />
    <design-themes
      v-if="walkthroughSettings.enablePersonalization && !isEmbedded"
      v-model:is-visible="toggleDesignThemes"
      :themes="themes"
      @on-select-theme="changeTheme"
      @on-close="showMobileUI"
    />
    <thumbnail-carrousel
      v-if="walkthroughSettings.enableFilmstripNavigation"
      v-model:is-visible="toggleCarrousel"
      :data="carrouselData"
      :live-scene="liveSceneRaw"
      @select-card="warpToHotspot"
      @on-close="showMobileUI"
    />
    <time-of-day-toggle
      v-if="walkthroughSettings.enableTimeOfDay"
      v-model:checked="isDaytime"
      @on-change="changeTimeOfDay"
    />
    <fullscreen-toggle v-if="!isEmbedded" @on-change="toggleFullscreen" />
  </div>
  <div class="mobile-button-container" v-if="displayMobileUI">
    <button class="mobile-button" @click="onToggleModify">
      <span>MODIFY</span><img src="../assets/Icon_Personalize.png" />
    </button>
    <button class="mobile-button" @click="onToggleRooms">
      <span>ROOMS</span><img src="../assets/Map_Icon.png" />
    </button>
    <button class="mobile-button" @click="onToggleMap">
      <span>MAP</span><img src="../assets/Map_Icon.png" />
    </button>
  </div>
</template>

<script setup>
import "pannellum";
import "pannellum/build/pannellum.css";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { WalkthroughConfig } from "../backend/index";
import * as TourUtil from "../util/TourUtil";
import * as StyleUtil from "../util/StyleUtil";
import DesignThemes from "./design_themes/DesignThemes.vue";
import MiniMap from "./minimap/MiniMap.vue";
import InfoPanelWidget from "./InfoPanelWidget.vue";
import ThumbnailCarrousel from "./carrousel/ThumbnailCarrousel.vue";
import TimeOfDayToggle from "./time_of_day/TimeOfDayToggle.vue";
import FullscreenToggle from "./fullscreen/FullscreenToggle.vue";
import { MobileState } from "@/models/MobileState";

//#region ------------------------------------------------------- \\ Emits // ------------------------------------------------------------>

const emit = defineEmits(["onToggleFullscreen"]);

//#region ----------------------------------------------------- \\ Properties // --------------------------------------------------------->

let configScenes = new Map();
let configMap = new Map();
let rafId = -1;
let viewer;

// Pannellum controls
const yaw = ref(-1);
const pitch = ref(-1);
const zoom = ref(-1);
// Data containers
const walkthroughDetails = ref({});
const walkthroughSettings = ref({});
const isEmbedded = ref(false);
// Properties
const liveSceneId = ref("");
const currentLevel = ref("");
const currentTheme = ref("1");
const currentTimeOfDay = ref("Day");
const carrouselData = ref([]);
const isDaytime = ref(true);
const hideRoom = ref(true);
// Display component toggles
const toggleMinimap = ref(false);
const toggleDesignThemes = ref(false);
const toggleCarrousel = ref(false);
// Display UI
const displayUI = ref(false);
const displayMobileUI = ref(false);
// Dynamic container references
const containerRef = ref();

//#region ----------------------------------------------------- \\ Mounting // ----------------------------------------------------------->

onMounted(() => {
  constructTour();

  // Setup media breakpoint listener
  var mediaQuery = window.matchMedia("(max-width: 1024px)");
  // Call listener function
  onBreakpointChanged(mediaQuery);
  // Attach listener function on state changes
  mediaQuery.addEventListener("change", onBreakpointChanged);
});

onBeforeUnmount(() => {
  viewer.destroy();
  window.cancelAnimationFrame(rafId);
});

//#region ------------------------------------------------- \\ Tour Construction // ------------------------------------------------------>

const constructTour = function () {
  // Grab our config file
  const stubConfig = WalkthroughConfig;
  // Apply settings from response data
  applyDefaultSettings(stubConfig);
  // Get a map of our vanilla day and night walkthroughs
  configMap = TourUtil.getWalkthroughMap(stubConfig);
  // Assign our first level
  currentLevel.value = levels.value.keys().next().value;
  // Grab the default walkthrough
  var defaultConfig = TourUtil.getDefaultConfig(
    configMap,
    walkthroughSettings.value
  );
  // Populate carrousel with scene data
  carrouselData.value = TourUtil.getCarrouselData(defaultConfig);
  // Assign our startup scene for minimap use
  liveSceneId.value = defaultConfig.default.firstScene;
  // Enrich config with (time of day + themed) scenes and classes
  var enrichedConfig = getEnrichedConfig(defaultConfig);
  // Create a reference to the configs scenes
  configScenes = TourUtil.getSceneMap(enrichedConfig);
  // Create and launch the viewer
  createAndLaunchViewer(enrichedConfig);
};

const applyDefaultSettings = function (baseConfig) {
  // Embedded Mode
  isEmbedded.value = baseConfig.isEmbeddedMode;
  // Gather walkthrough details
  walkthroughDetails.value = baseConfig.walkthroughDetails;
  // Walkthrough settings
  walkthroughSettings.value = baseConfig.settings;

  // Apply minimap toggle
  toggleMinimap.value = walkthroughSettings.value.enableMinimapOnStart;
  // Apply design themes toggle
  toggleDesignThemes.value = false;
  // Apply carrousel toggle
  toggleCarrousel.value = true;

  // Assign default time of day
  currentTimeOfDay.value = walkthroughSettings.value.defaultTimeOfDay;
  // Apply branding settings
  StyleUtil.applyBrandSettings();
};

const getEnrichedConfig = function (defaultConfig) {
  var config = defaultConfig;
  // Build (time of day + themed) versions of each scene
  config.scenes = TourUtil.getAllScenes(configMap);
  // Add custom classes to hotspots
  config = TourUtil.injectClasses(
    config,
    walkthroughSettings.value.hotspotScaleFactor,
    morphSelectedHotspot
  );
  return config;
};

const createAndLaunchViewer = function (enrichedConfig) {
  console.log(enrichedConfig);
  // Add config file to pannellum
  viewer = window.pannellum.viewer("pannellumContainer", enrichedConfig);
  // Start looping
  rafId = window.requestAnimationFrame(renderLoop);
  // Listen to scene changes
  viewer.on("scenechange", onSceneChange);
  // Listen to scene load event
  viewer.on("load", onLoadComplete);
  // Override pannellum's default styles
  TourUtil.applyDefaultStyles();
};

//#region ----------------------------------------------------- \\ Functions // ---------------------------------------------------------->

// Called when a minimap dot, or carrousel thumbnail is selected
const warpToHotspot = function (rawSceneId) {
  var sceneId = `${currentTimeOfDay.value}_T${currentTheme.value}_${rawSceneId}`;
  // Update the scene we are warping to
  viewer.removeScene(sceneId);
  // Copy origianl data
  var sceneData = JSON.parse(JSON.stringify(configScenes.get(sceneId)));
  sceneData = TourUtil.getSceneWithClasses(
    sceneData,
    configScenes,
    walkthroughSettings.value.hotspotScaleFactor,
    morphSelectedHotspot
  );
  // Assign current fov to scene
  sceneData.hfov = zoom.value;
  // Add the scene back into pannellum
  viewer.addScene(sceneId, sceneData);
  // Finally, load our scene
  viewer.loadScene(sceneId);
  TourUtil.applyDefaultStyles();
};
// Callback used to modify a hotspot's scene before it loads
const morphSelectedHotspot = function (clickEvent, sceneId) {
  console.log(`morphing: ${sceneId}`);
  if (viewer) {
    // Update the scene we are warping to
    viewer.removeScene(sceneId);
    // Copy origianl data
    var sceneData = JSON.parse(JSON.stringify(configScenes.get(sceneId)));
    sceneData = TourUtil.getSceneWithClasses(
      sceneData,
      configScenes,
      walkthroughSettings.value.hotspotScaleFactor,
      morphSelectedHotspot
    );
    // Assign current fov to scene
    sceneData.hfov = zoom.value;
    // Add the scene back into pannellum
    viewer.addScene(sceneId, sceneData);
  }
};
const toggleFullscreen = function () {
  emit("onToggleFullscreen");
};
const changeTimeOfDay = function (isDay) {
  currentTimeOfDay.value = isDay ? "Day" : "Dusk";
  var sceneId = `${currentTimeOfDay.value}_T${currentTheme.value}_${liveSceneRaw.value}`;
  // Update the time of day scene we're warping to
  viewer.removeScene(sceneId);
  // Copy origianl scene data
  var sceneData = JSON.parse(JSON.stringify(configScenes.get(sceneId)));
  sceneData = TourUtil.getSceneWithClasses(
    sceneData,
    configScenes,
    walkthroughSettings.value.hotspotScaleFactor,
    morphSelectedHotspot
  );
  // Assign live values to our themed scene
  sceneData.yaw = yaw.value;
  sceneData.pitch = pitch.value;
  sceneData.hfov = zoom.value;
  // Add the scene back into pannellum
  viewer.addScene(sceneId, sceneData);
  // Finally, load our themed scene
  viewer.loadScene(sceneId);
  TourUtil.applyDefaultStyles();
};

const changeTheme = function (themeId) {
  console.log(`update to theme: ${themeId}`);
  var sceneId = `${currentTimeOfDay.value}_T${themeId}_${liveSceneRaw.value}`;
  // Update the themed scene
  viewer.removeScene(sceneId);
  // Copy origianl theme data
  var sceneData = JSON.parse(JSON.stringify(configScenes.get(sceneId)));
  sceneData = TourUtil.getSceneWithClasses(
    sceneData,
    configScenes,
    walkthroughSettings.value.hotspotScaleFactor,
    morphSelectedHotspot
  );
  // Assign live values to our themed scene
  sceneData.yaw = yaw.value;
  sceneData.pitch = pitch.value;
  sceneData.hfov = zoom.value;
  // Add the scene back into pannellum
  viewer.addScene(sceneId, sceneData);
  // Finally, load our themed scene
  viewer.loadScene(sceneId);
  TourUtil.applyDefaultStyles();
  // Update our theme
  currentTheme.value = themeId;
};

const changeMobileState = function (state) {
  switch (state) {
    case MobileState.DESIGN_THEMES:
      toggleCarrousel.value = false;
      toggleMinimap.value = false;
      toggleDesignThemes.value = true;
      // Disable mobile UI
      displayMobileUI.value = false;
      break;
    case MobileState.ROOM_CARROUSEL:
      toggleDesignThemes.value = false;
      toggleMinimap.value = false;
      toggleCarrousel.value = true;
      // Disable mobile UI
      displayMobileUI.value = false;
      break;
    case MobileState.MINI_MAP:
      toggleDesignThemes.value = false;
      toggleCarrousel.value = false;
      toggleMinimap.value = true;
      // Disable mobile UI
      displayMobileUI.value = false;
      break;
    case MobileState.NONE:
      toggleDesignThemes.value = false;
      toggleCarrousel.value = false;
      toggleMinimap.value = false;
      break;
  }
};

const showMobileUI = function () {
  displayMobileUI.value = true;
};

//#region ----------------------------------------------------- \\ Callbacks // ---------------------------------------------------------->

const onBreakpointChanged = function (mediaQuery) {
  // If we are viewing the app on a smaller screen
  if (mediaQuery.matches) {
    // Close our components, for now
    changeMobileState(MobileState.NONE);
    // Enable mobile UI
    displayMobileUI.value = true;
  } else {
    // Just display the carrousel as that is always visible on desktop
    changeMobileState(MobileState.ROOM_CARROUSEL);
    // Disable mobile UI
    displayMobileUI.value = false;
  }
};

const onToggleModify = function () {
  changeMobileState(MobileState.DESIGN_THEMES);
};
const onToggleRooms = function () {
  changeMobileState(MobileState.ROOM_CARROUSEL);
};
const onToggleMap = function () {
  changeMobileState(MobileState.MINI_MAP);
};

const onToggleInfoPanel = function (isVisible) {
  hideRoom.value = !isVisible;
};

// Capture changes on each frame
const renderLoop = function () {
  rafId = window.requestAnimationFrame(renderLoop);
  yaw.value = viewer.getYaw();
  pitch.value = viewer.getPitch();
  zoom.value = viewer.getHfov();
};

const onLoadComplete = function () {
  TourUtil.applyDefaultStyles();
  displayUI.value = true;
};

const onSceneChange = function (sceneId) {
  if (configScenes) {
    // Get our raw sceneId
    var rawSceneId = TourUtil.getRawSceneId(sceneId);
    // Reset previously themed scenes
    if (liveSceneRaw.value != rawSceneId) {
      themes.value.forEach((themeValue, themeKey) => {
        var tempSceneId = `${currentTimeOfDay.value}_T${themeKey}_${rawSceneId}`;
        // Remove modified scene
        viewer.removeScene(tempSceneId);
        // Grab a copy of the original unmodified scene
        var tempScene = configScenes.get(tempSceneId);
        // Add original scene back
        viewer.addScene(tempSceneId, tempScene);
      });
    }
    // Assign the level that the sceneId lives on
    currentLevel.value = [...configScenes].filter(
      ({ 0: sceneKey }) => sceneKey === sceneId
    )[0][1].level;
  }
  // Notify minimap that our scene has changed
  liveSceneId.value = sceneId;
  console.log(`Scene changed to: ${sceneId}`);
};
const onLevelChange = function (level) {
  if (configScenes) {
    // Grab the first scene that contains the desired level
    var toLevelWarp = [...configScenes].filter(
      ({ 1: sceneValue }) => sceneValue.level === level
    )[0][0];
    warpToHotspot(TourUtil.getRawSceneId(toLevelWarp));
  }
};

//#region ----------------------------------------------------- \\ Computed // ----------------------------------------------------------->

const liveSceneRaw = computed(() => {
  return liveSceneId.value ? TourUtil.getRawSceneId(liveSceneId.value) : "";
});

const getThemePrice = computed(() =>
  themes.value ? themes.value.get(currentTheme.value).themePrice : 0
);

const levels = computed(() => {
  // Assign levels map
  return TourUtil.getLevelMap(configMap, currentTimeOfDay.value);
});

const themes = computed(() => {
  // Assign map for design themes
  return TourUtil.getThemeMap(configMap, currentTimeOfDay.value);
});
</script>

<style scoped>
#pannellumContainer {
  height: 100vh;
}
.room-title-container {
  @apply absolute top-8 left-0 w-full h-auto select-none;
}
.room-title-box {
  @apply relative select-none whitespace-nowrap
  flex flex-col items-center;
}
.room-title {
  @apply relative inline px-5 text-white uppercase text-center select-none whitespace-normal
  text-lg lg:text-xl;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-left: 0;
  border-right: 0;
  border-image-source: linear-gradient(to left, #00000000, #ffffff, #00000000);
  text-shadow: 3px 0px 7px rgba(81, 67, 21, 0.6),
    -3px 0px 7px rgba(81, 67, 21, 0.6), 0px 4px 7px rgba(81, 67, 21, 0.6);
}
.invisibleOnMobile {
  @apply hidden lg:block;
}
.mobile-button-container {
  @apply absolute w-full px-12 bottom-5 grid-flow-col grid-cols-3 gap-4 z-10
  grid lg:hidden;
}
.mobile-button {
  @apply relative w-20 h-20 mx-auto border-2 border-white rounded-full backdrop-blur-sm;
  background-color: rgba(255, 255, 255, 0.5);
}
.mobile-button span {
  @apply inline-block w-full p-2 mt-9 uppercase text-xs font-semibold;
}
.mobile-button img {
  @apply absolute top-3 w-8;
  left: calc(50% - 1rem);
}
</style>
