export const applyBrandSettings = function () {
  // document.documentElement.style.setProperty('--ls-text-xl', fontPrefs.h1StyleFont);
  // document.documentElement.style.setProperty('--ls-text-lg', fontPrefs.h2StyleFont);
  // document.documentElement.style.setProperty('--ls-text-md', fontPrefs.h3StyleFont);
  // document.documentElement.style.setProperty('--ls-text-base', fontPrefs.paragraphStyleFont);

  // document.documentElement.style.setProperty('--ls-primary-font', fontPrefs.primaryFontUrl.split("+").join(" "));
  // document.documentElement.style.setProperty('--ls-secondary-font', fontPrefs.secondaryFontUrl.split("+").join(" "));
  // document.documentElement.style.setProperty('--ls-tertiary-font', fontPrefs.tertiaryFontUrl.split("+").join(" "));
  // document.documentElement.style.setProperty('--ls-primary-color', colorPrefs.primaryColour);
  // document.documentElement.style.setProperty('--ls-secondary-color', colorPrefs.secondary);
  // document.documentElement.style.setProperty('--ls-tertiary-color', colorPrefs.tertiaryColor);
  // document.documentElement.style.setProperty('--ls-primary-font-color', colorPrefs.primaryFontColour);
  // document.documentElement.style.setProperty('--ls-secondary-font-color', colorPrefs.secondaryFontColour);
  // document.documentElement.style.setProperty('--ls-tertiary-font-color', colorPrefs.tertiaryFontColour);

  // document.documentElement.style.setProperty('--ls-highlight-color1', colorPrefs.highlightColour1);
  // document.documentElement.style.setProperty('--ls-highlight-color2', colorPrefs.highlightColour2);
  // document.documentElement.style.setProperty('--ls-highlight-color3', colorPrefs.highlightColour3);
  // document.documentElement.style.setProperty('--ls-footer-color', colorPrefs.footerColour);
  // document.documentElement.style.setProperty('--ls-footer-text-color', colorPrefs.footerTextColour);
  // document.documentElement.style.setProperty('--ls-footer-text-highlight-color', colorPrefs.footerTextHighlightColour);
  // document.documentElement.style.setProperty('--ls-list-view-bg-color', "#08a2cb");
  // document.documentElement.style.setProperty('--ls-other-build-color', "#add45b");
  // document.documentElement.style.setProperty('--ls-spec-build-color', "#ebaf51");
  // document.documentElement.style.setProperty('--ls-', "#08a2cb");

  // document.documentElement.style.setProperty('--go-secondary-text-color', colorPrefs.highlightColour1);
  // document.documentElement.style.setProperty('--slider-handle-bg', colorPrefs.highlightColour1);
  // document.documentElement.style.setProperty('--slider-connect-bg', colorPrefs.highlightColour1);
  // document.documentElement.style.setProperty('--slider-tooltip-bg', "transparent");
  // document.documentElement.style.setProperty('--slider-tooltip-color', colorPrefs.highlightColour1);
  // document.documentElement.style.setProperty('--slider-bg', "#FFF");
  // document.documentElement.style.setProperty('--slider-handle-border', "3px solid white");
  // document.documentElement.style.setProperty('--slider-handle-width', "26px");
  // document.documentElement.style.setProperty('--slider-handle-height', "26px");

  // document.documentElement.style.setProperty('--toggle-width', "160px");
  // document.documentElement.style.setProperty('--toggle-bg-on', "#FFF");
  // document.documentElement.style.setProperty('--toggle-bg-off', "#FFF");
  // document.documentElement.style.setProperty('--toggle-border-on', colorPrefs.highlightColour1);
  // document.documentElement.style.setProperty('--toggle-border-off', colorPrefs.tertiaryColor);
  // document.documentElement.style.setProperty('--toggle-height', "32px");
  // document.documentElement.style.setProperty('--toggle-font-size', "15px");
  // document.documentElement.style.setProperty('--toggle-text-on', colorPrefs.highlightColour1);
  // document.documentElement.style.setProperty('--toggle-text-off', colorPrefs.tertiaryColor);
  // document.documentElement.style.setProperty('--toggle-ring-width', "1px");
  // document.documentElement.style.setProperty('--toggle-ring-color', "#000");

  document.documentElement.style.setProperty("--ls-primary-color", "#fcfcfc");
  document.documentElement.style.setProperty("--ls-secondary-color", "#f4f4f4");
  document.documentElement.style.setProperty("--ls-tertiary-color", "#adadad");
  document.documentElement.style.setProperty(
    "--ls-primary-font-color",
    "#3f98cc"
  );
  document.documentElement.style.setProperty(
    "--ls-secondary-font-color",
    "#2e2925"
  );
  document.documentElement.style.setProperty(
    "--ls-tertiary-font-color",
    "#256489"
  );
  document.documentElement.style.setProperty(
    "--ls-highlight-color1",
    "#3f98cc"
  );
  document.documentElement.style.setProperty(
    "--ls-highlight-color2",
    "#cfdb67"
  );
  document.documentElement.style.setProperty(
    "--ls-highlight-color3",
    "#d894eb"
  );
  document.documentElement.style.setProperty("--ls-footer-color", "#f4b778");
  document.documentElement.style.setProperty(
    "--ls-footer-text-color",
    "#ffffff"
  );
  document.documentElement.style.setProperty(
    "--ls-footer-text-highlight-color",
    "#5557b1"
  );
  document.documentElement.style.setProperty(
    "--ls-list-view-bg-color",
    "#78b4d7"
  );
  document.documentElement.style.setProperty(
    "--ls-other-build-color",
    "#add45b"
  );
  document.documentElement.style.setProperty(
    "--ls-spec-build-color",
    "#ebaf51"
  );
};

//create full hex
const fullHex = (hex) => {
  let r = hex.slice(1, 2);
  let g = hex.slice(2, 3);
  let b = hex.slice(3, 4);

  r = parseInt(r + r, 16);
  g = parseInt(g + g, 16);
  b = parseInt(b + b, 16);

  // return {r, g, b}
  return { r, g, b };
};

//convert hex to rgb
export const hex2rgb = (hex) => {
  if (hex.length === 4) {
    return fullHex(hex);
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return { r, g, b };
};

const getHue = function (red, green, blue) {
  var min = Math.min(Math.min(red, green), blue);
  var max = Math.max(Math.max(red, green), blue);

  if (min == max) {
    return 0;
  }

  var hue = 0;
  if (max == red) {
    hue = (green - blue) / (max - min);
  } else if (max == green) {
    hue = 2 + (blue - red) / (max - min);
  } else {
    hue = 4 + (red - green) / (max - min);
  }

  hue = hue * 60;
  if (hue < 0) hue = hue + 360;

  return Math.round(hue);
};

export const getHueFromHex = function (hexColor) {
  var rbgColor = hex2rgb(hexColor);
  return getHue(rbgColor.r, rbgColor.g, rbgColor.b);
};

export const getHueFromRgb = function (red, green, blue) {
  return getHue(red, green, blue);
};

export const getBrightnessFromRgb = function (red, green, blue) {
  return (1 + ((red * 299 + green * 587 + blue * 114) / 1000 / 255)).toFixed(2);
};
