<template>
  <div class="main-container">
    <div class="toggle-area">
      <label
        class="flex items-center relative w-max cursor-pointer select-none"
      >
        <button class="button-area" @click="selectFullscreen" />
      </label>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["onChange"]);

const selectFullscreen = function () {
  emit("onChange");
};
</script>

<style scoped>
.main-container {
  @apply absolute z-10
  hidden right-4 top-16 lg:right-8 lg:top-20 lg:block;
  transition: all 0.15s;
  transform-origin: top right;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
  border-radius: 9999px;
}
.main-container:hover {
  @apply lg:scale-125;
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.2),
    0 3px 10px 1px rgba(0, 0, 0, 0.2);
}
.toggle-area {
  @apply md:w-full mb-6 md:mb-0 grid;
}
.button-area {
  @apply appearance-none transition-colors cursor-pointer w-8 h-8 rounded-lg 
  ring-1 ring-transparent hover:ring-white bg-transparent;
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}
button {
  background-image: url("../../assets/fullscreen-svgrepo-com.svg");
  background-size: contain;
}
</style>
