<template>
  <div v-if="props.isVisible">
    <div class="main-container">
      <div
        class="carrousel-container"
        ref="carrouselContainer"
        v-dragscroll.x
        v-on:dragscrollmove="onDragMove"
        v-on:dragscrollend="onDragEnd"
        v-on:scroll="onScrollChange"
      >
        <div class="carrousel-flex" ref="carrouselFlex">
          <carrousel-card
            v-for="(card, index) in props.data"
            :key="index"
            :card-title="card.title"
            :card-thumbnail="card.thumbnail"
            :selected-title="liveScene"
            @click="clickHandler(card.title)"
          />
        </div>
      </div>
      <button class="close-button" @click="onClose" />
    </div>
    <div class="clone-container">
      <div class="carrousel-container-skinny" ref="carrouselSkinny">
        <div class="carrousel-clone"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import CarrouselCard from "./CarrouselCard.vue";
import { defineProps, defineEmits, ref, computed } from "vue";

const props = defineProps({
  isVisible: { type: Boolean, required: true },
  liveScene: { type: String, required: true },
  data: { type: Array, required: true },
});

const emit = defineEmits(["onClose", "update:isVisible", "selectCard"]);

//#region Dynamic Refs
const carrouselFlex = ref();
const carrouselContainer = ref();
const carrouselSkinny = ref();
//#region Properties
let allowSelectCard = true;

//#region Event Handlers
const onClose = function () {
  emit("update:isVisible", false);
  emit("onClose");
};

const clickHandler = function (title) {
  if (allowSelectCard && title != props.liveScene) {
    emit("selectCard", title);
  }
};
const onDragEnd = function (eventData) {
  setTimeout(() => {
    allowSelectCard = true;
  }, 100);
};
const onDragMove = function (eventData) {
  if (eventData.detail.deltaX != 0) {
    allowSelectCard = false;
  }
};
const onScrollChange = function (eventData) {
  if (props.isVisible) {
    var maxScrollLeft =
      carrouselFlex.value.scrollWidth - carrouselFlex.value.clientWidth;
    var widthPercent =
      carrouselContainer.value.scrollLeft > 0
        ? carrouselContainer.value.scrollLeft / maxScrollLeft
        : 0;
    var skinnyScrollWidth =
      carrouselSkinny.value.scrollWidth - carrouselSkinny.value.clientWidth;
    // Remap cloned scrollbar
    var relativePosition = skinnyScrollWidth * widthPercent;
    // Apply modified scroll position
    carrouselSkinny.value.scrollLeft = relativePosition;
  }
};
//#region Computed
const carrouselWidth = computed(() => {
  return props.isVisible ? `${carrouselFlex.value.offsetWidth}px` : "0px";
});
</script>
<style scoped>
.main-container {
  @apply absolute left-0 w-full pointer-events-none
  h-[12%] lg:h-[6%] bottom-10 lg:bottom-[1em];
}
.clone-container {
  @apply absolute left-0 w-full pointer-events-none
  h-[12%] lg:h-[6%] bottom-[1em] lg:bottom-[0.3em];
}
.carrousel-container {
  @apply relative block h-full rounded-xl m-auto
  w-[95%] lg:w-1/3
  overflow-x-hidden overflow-y-hidden backdrop-blur-lg;
  background-color: rgba(255, 255, 255, 0.25);
}
.carrousel-container-skinny {
  @apply relative block h-full rounded-xl m-auto 
  overflow-x-scroll overflow-y-visible
  w-[65%] lg:w-[13%];
}
.carrousel-flex {
  @apply relative h-full rounded-xl flex flex-row items-end gap-1
  pointer-events-auto overflow-y-visible;
}
.carrousel-clone {
  @apply relative h-full pointer-events-none;
  width: v-bind(carrouselWidth);
}
.close-button {
  @apply block lg:hidden;
  position: absolute;
  right: 2%;
  bottom: 115%;
  height: 4em;
  width: 4em;
  background-image: url("../../assets/close-circle-svgrepo-com.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 12;
  pointer-events: auto;
}
/* Gradient Scroll Bar */

/* width */
::-webkit-scrollbar {
  @apply h-3 border border-transparent border-solid rounded-full
  lg:h-2;
}

/* Track */
::-webkit-scrollbar-track {
  @apply rounded-full bg-[#bababa87];
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply rounded-full;
  background: linear-gradient(
    to left,
    var(--ls-footer-text-highlight-color),
    var(--ls-highlight-color3),
    var(--ls-footer-color)
  );
}
</style>
