<template>
  <div class="design-themes-base">
    <div class="design-themes-inactive" v-if="!props.isVisible">
      <button class="open-button open-button-idle" @click="onToggle()">
        <div class="customize-plan-container">
          <img src="../../assets/Icon_Personalize.png" />
          <span class="customize-plan-separator">|</span>
          <span class="design-themes-title-inactive">Personalize Plan...</span>
        </div>
      </button>
    </div>
    <div class="design-themes-active design-themes-base-background" v-else>
      <div class="title-container">
        <span class="design-themes-title-active">Design Themes</span>
      </div>
      <div
        v-dragscroll
        v-on:dragscrollmove="onDragMove"
        v-on:dragscrollend="onDragEnd"
        class="card-container"
      >
        <design-theme-card
          v-for="[tKey, tValue, tIndex] in props.themes"
          :key="tIndex"
          :theme-id="tKey"
          :theme-data="tValue"
          :selected-theme="selectedTheme"
          @click="selectTheme(tKey)"
        />
      </div>
      <button class="open-button absolute bottom-0 left-0" @click="onToggle()">
        <div class="customize-plan-container">
          <img src="../../assets/Icon_Personalize.png" />
          <span class="customize-plan-separator">|</span>
          <span class="design-themes-title-inactive">Personalize Plan...</span>
        </div>
      </button>
      <button class="close-button" @click="onClose()" />
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from "vue";
import DesignThemeCard from "./DesignThemeCard.vue";

const props = defineProps({
  isVisible: { type: Boolean, required: true },
  themes: { type: Map, required: true },
});

const emit = defineEmits(["onClose", "onSelectTheme", "update:isVisible"]);

const selectedTheme = ref("1");

let allowThemeChange = true;

const selectTheme = function (themeId) {
  if (allowThemeChange) {
    selectedTheme.value = themeId;
    emit("onSelectTheme", themeId);
  }
};

const onClose = function () {
  emit("update:isVisible", false);
  emit("onClose");
};

const onToggle = function () {
  emit("update:isVisible", !props.isVisible);
};

// const bodyHeight = computed(() => {
//   return props.themes.size > 2 ? "72%" : "auto";
// });

const onDragEnd = function (eventData) {
  setTimeout(() => {
    allowThemeChange = true;
  }, 100);
};
const onDragMove = function (eventData) {
  if (eventData.detail.deltaY != 0 || eventData.detail.deltaX != 0) {
    allowThemeChange = false;
  }
};
</script>

<style scoped>
.design-themes-base-background {
  @apply backdrop-blur-lg rounded-xl select-none;
  background-color: color-mix(
    in srgb,
    var(--ls-secondary-color),
    transparent 50%
  );
}
.design-themes-base {
  @apply absolute bg-transparent rounded-xl
  left-0 lg:left-10 bottom-0 lg:bottom-[1em] p-4 lg:p-0
  w-full lg:w-[25%] 2xl:w-[18%] 3xl:w-[14%]
  h-auto lg:h-[72%]
  select-none pointer-events-none;
}
.design-themes-active {
  @apply relative w-full h-full
  pointer-events-auto;
}
.design-themes-inactive {
  @apply relative w-full h-full flex flex-row items-end;
}
.title-container {
  @apply relative flex items-center justify-center w-full rounded-t-xl;
  /* background-color: rgba(136, 136, 136, 0.75); */
  background-color: color-mix(
    in srgb,
    var(--ls-primary-color),
    transparent 25%
  );
  height: 7%;
}
.design-themes-title-active {
  @apply relative text-ls-secondary-font-color uppercase font-semibold p-2 select-none
  text-base lg:text-lg;
}
.design-themes-title-inactive {
  @apply relative text-ls-secondary-font-color text-sm uppercase font-semibold select-none;
}
.customize-plan-container {
  @apply relative flex flex-row items-center gap-3 justify-center w-full p-1 rounded-t-lg rounded-b-lg;
  background-color: color-mix(
    in srgb,
    var(--ls-secondary-color),
    transparent 25%
  );
  backdrop-filter: blur(6px);
  transition: all 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.customize-plan-separator {
  @apply text-lg font-normal text-ls-tertiary-color select-none;
}
.customize-plan-container img {
  @apply w-7 select-none;
}
.customize-plan-container:hover {
  @apply bg-white;
}
.card-container {
  @apply relative grid gap-1 place-items-end
  my-0 mx-1.5 lg:my-3 lg:mx-2
  grid-flow-col lg:grid-flow-row
  overflow-y-hidden lg:overflow-y-scroll 
  overflow-x-scroll lg:overflow-x-hidden;
  max-height: calc(91.25% - 2rem);
}
.close-button {
  @apply absolute lg:right-[-5%] lg:top-[-8%] lg:w-[18%] lg:h-[18%]
  -right-0.5 top-[-4.5em] w-16 h-16;
  background-image: url("../../assets/close-circle-svgrepo-com.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 12;
  pointer-events: auto;
}
.open-button {
  @apply w-full h-auto rounded-lg pointer-events-auto
  hidden lg:block;
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.28);
}
.open-button-idle {
  @apply hidden lg:block;
}

/* Gradient Scroll Bar */

/* width */
::-webkit-scrollbar {
  @apply w-auto lg:w-[6px] h-3 lg:h-auto;
}

/* Track */
::-webkit-scrollbar-track {
  @apply rounded-full bg-[#bababa87];
}

/* Handle */
::-webkit-scrollbar-thumb:vertical {
  @apply rounded-full;
  background: linear-gradient(
    var(--ls-footer-text-highlight-color),
    var(--ls-highlight-color3),
    var(--ls-footer-color)
  );
}
::-webkit-scrollbar-thumb:horizontal {
  @apply rounded-full;
  background: linear-gradient(
    to left,
    var(--ls-footer-text-highlight-color),
    var(--ls-highlight-color3),
    var(--ls-footer-color)
  );
}
</style>
