<template>
  <div class="widget-body">
    <img
      class="poi-icon"
      src="../assets/LivesitePinVector.svg"
      @click="onClickPoi"
    />
    <div :class="{ infoPanelToggle: panelToggle }">
      <div class="title-area">
        <span class="plan-text">{{ props.walkthroughSettings.planName }}</span>
        <span class="separator px-1 lg:px-3">|</span>
        <span class="elevation-text"
          >Elevation {{ props.walkthroughSettings.elevationName }}</span
        >
        <span v-if="displayDescription" class="description-text">{{
          props.walkthroughSettings.description
        }}</span>
      </div>
      <div>
        <div class="details-row-1">
          <div v-if="displayPrice" class="detail-text">
            <span class="detail-title-text">FROM</span>&nbsp;&nbsp;<span
              class="text-ls-tertiary-font-color font-semibold"
              >${{ formattedPrice }}</span
            >
          </div>
          <span v-if="displayPrice" class="separator">|</span>
          <div v-if="displayFootage" class="detail-text">
            <span class="detail-title-text">SQ.FT.</span>&nbsp;&nbsp;<span
              class="text-ls-tertiary-font-color font-normal"
              >{{
                formatThousands(props.walkthroughSettings.baseSize)
              }}
              sq.ft.</span
            >
          </div>
        </div>
        <div v-if="displayAttributes" class="details-row-2">
          <div class="detail-icon">
            <div class="icon bed-icon" />
          </div>
          <span class="separator">|</span>
          <div class="detail-icon">
            <div class="icon bath-icon" />
          </div>
          <span class="separator">|</span>
          <div class="detail-icon">
            <div class="icon car-icon" />
          </div>
        </div>
        <div v-if="displayAttributes" class="details-row-3">
          <div class="detail-text">
            <p>{{ props.walkthroughSettings.baseBeds }}</p>
          </div>
          <div class="detail-text">
            <p>{{ props.walkthroughSettings.baseBaths }}</p>
          </div>
          <div class="detail-text">
            <p>2</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { formatThousands } from "@/util/RegexUtil";
import { computed, ref } from "vue";

const props = defineProps({
  walkthroughSettings: { type: Object, required: true },
  themePrice: { type: Number, required: true },
  displayPrice: { type: Boolean, required: true },
  displayDescription: { type: Boolean, required: true },
  displayFootage: { type: Boolean, required: true },
  displayAttributes: { type: Boolean, required: true },
});

const emit = defineEmits(["onTogglePanel"]);

const panelToggle = ref(false);

const onClickPoi = function () {
  panelToggle.value = !panelToggle.value;
  emit("onTogglePanel", panelToggle.value);
};

//#region Computed

const formattedPrice = computed(() =>
  (props.walkthroughSettings.basePrice + props.themePrice).toLocaleString(
    "en-US"
  )
);

const titlePaddingLarge = computed(() => {
  return props.displayDescription ? "0" : "0.75rem";
});

const titlePaddingSmall = computed(() => {
  return props.displayDescription ? "0" : "0.5rem";
});

//#endregion
</script>
<style scoped>
.widget-body {
  @apply absolute rounded-xl backdrop-blur-lg
  w-[60%] md:w-[42%] lg:w-[32%] 2xl:w-[22%] 3xl:w-[18%] 4xl:w-[18%]
  left-5 top-5 lg:left-10 lg:top-10;
  background-color: color-mix(
    in srgb,
    var(--ls-secondary-color),
    transparent 25%
  );
}
/* .info-panel-body {
  display: none
} */
.details-row-1 {
  @apply relative w-full px-4 flex flex-row gap-1;
}
.details-row-2 {
  @apply relative w-full px-10 flex flex-row gap-4;
}
.details-row-3 {
  @apply relative w-full px-7 flex flex-row gap-4;
}
.detail-title-text {
  @apply text-ls-tertiary-color uppercase
  text-2xs lg:text-xs;
}
.detail-text {
  @apply relative flex-grow text-center
  text-xs lg:text-lg pt-1 lg:pt-2;
}
.detail-icon {
  @apply relative mx-auto text-lg text-center
  pt-0.5 lg:pt-2;
}
.detail-text p {
  @apply block whitespace-normal pb-1 text-ls-tertiary-font-color;
}
.title-area {
  @apply w-full inline-block font-bold text-center bg-ls-primary-color rounded-t-xl
  text-base lg:text-xl pt-2 pb-[v-bind(titlePaddingSmall)] lg:pt-3 lg:pb-[v-bind(titlePaddingLarge)];
}
.plan-text {
  @apply uppercase text-ls-primary-font-color;
}
.elevation-text {
  @apply font-normal text-ls-secondary-font-color
  text-sm lg:text-base;
}
.description-text {
  @apply inline-block px-2 py-1 font-normal text-xs;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-image-source: linear-gradient(
    to left,
    #00000000,
    rgba(128, 128, 128, 0.2),
    #00000000
  );
}
.separator {
  @apply font-normal
  pt-0.5 lg:pt-1 text-sm lg:text-xl;
  color: color-mix(in srgb, var(--ls-tertiary-color), transparent 80%);
}
.icon {
  @apply relative float-left h-full
  w-6 lg:w-12;
  background-image: url("../assets/Icon_Bed.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.bed-icon {
  background-image: url("../assets/Icon_Bed.png");
}
.bath-icon {
  background-image: url("../assets/Icon_Bath.png");
}
.car-icon {
  background-image: url("../assets/Icon_Garage.png");
}
.poi-icon {
  @apply absolute transition-all select-none
  -left-4 -top-4 w-8 lg:-left-6 lg:-top-3 lg:w-12;
  transform-origin: bottom;
}
.poi-icon:hover {
  @apply cursor-pointer lg:scale-125;
}
.infoPanelToggle {
  display: none;
}
</style>
